import { useEffect } from "react";
import * as Api from "../../../../Api";
import { SharedClaim } from "../../../../api/sharedClaim";

import config from "../../../../config";

export function useShareAttachmentsHook({
    claimId,
    selectedFolderId,
    selectedFilesId,
    setErrorMessage,
    setIsShowSweetAlertError,
    setIsShowShareUrl,
    setShareUrl,
    isSharedView,
    setUploadRequest,
    setInboundEmailAddress,
    setAllowInboundEmail
}) {
    const { appUrl } = config;
    const shareAll = async () => {
        try{
            const requestData = {
                claimId,
                selectedFilesId: [],
                type: "all"
            };
            if(isSharedView){
                const sharedData = await SharedClaim.getAttachmentShareToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }
            if(!isSharedView){
                const sharedData = await Api.Claims.Attachments.share.getToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }

        }catch(err) {
            console.log("Getting Error into share all attachments.")
            console.log(err);
        };
    };

    const shareSelected = async () => {
        try{
            if(selectedFilesId.length > 0){
                const requestData = {
                    claimId,
                    defaultFolderId:selectedFolderId,
                    selectedFilesId: selectedFilesId,
                    type: "selected"
                };
                if(isSharedView){
                    const sharedData = await SharedClaim.getAttachmentShareToken({
                        requestData
                    });
                    setIsShowShareUrl(true);
                    const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                    setShareUrl(newUrl);
                }
                if(!isSharedView){
                    const sharedData = await Api.Claims.Attachments.share.getToken({
                        requestData
                    });
                    setIsShowShareUrl(true);
                    const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                    setShareUrl(newUrl);
                }
            }
            if(selectedFilesId.length === 0){
                setIsShowSweetAlertError(true);
                setErrorMessage("Please first select file to share.")
            }
        }catch(err) {
            console.log("Getting Error into share all attachments.")
            console.log(err);
        };
    }

    const shareSelectedFolder = async ({
        folderId
    }) => {
        try{
            const requestData = {
                claimId,
                selectedFilesId: selectedFilesId,
                type: "folder",
                defaultFolderId: folderId
            };
            if(isSharedView){
                const sharedData = await SharedClaim.getAttachmentShareToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }
            if(!isSharedView){
                const sharedData = await Api.Claims.Attachments.share.getToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }
        }catch(err) {
            console.log("Getting Error into share all attachments.")
            console.log(err);
        };
    }

    const shareUpload = async () => {
        let uploadRequest = await Api.UploadRequest.getByClaim({claim_id: claimId});
        if (!uploadRequest) {
            uploadRequest = await Api.UploadRequest.create({claim_id: claimId});
        }
        uploadRequest.url = `${appUrl}/claim/upload/${uploadRequest.request_id}`;
        setUploadRequest(uploadRequest);
    };

    const setInboundEmail = async mailbox => {
        console.log(`setting mailbox to ${mailbox}`);
        const mailboxResponse = await Api.Claims.setInboundEmail(claimId, {mailbox});
        if (mailboxResponse.data.success) {
            setInboundEmailAddress(mailboxResponse.data.email);
            setAllowInboundEmail(mailboxResponse.data.mailbox.length > 0);
        }
    };

    useEffect(() => {
        const getInboundEmail = async () => {
            const mailboxResponse = await Api.Claims.getInboundEmail(claimId);
            if (mailboxResponse.data.mailbox) {
                setInboundEmailAddress(mailboxResponse.data.email);
                setAllowInboundEmail(true);
            } else {
                setInboundEmailAddress('');
                setAllowInboundEmail(false);
            }
        };
        getInboundEmail();
    }, [claimId, setInboundEmailAddress, setAllowInboundEmail]);

    return {
        shareAll,
        shareSelected,
        shareSelectedFolder,
        shareUpload,
        setInboundEmail
    }
}
