import React, {useEffect, useState} from 'react';
import {Pagebar} from '../layout/Pagebar';

import * as Api from '../../Api';
import { AddPaymentMethodModal } from './addPaymentMethodModal';
import Modal from "react-modal";
import './newPaymentMethods.css';


export function NewPaymentMethods() {

    const [paymentMethods, setPaymentMethods] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    // const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState('');
    const [modalIsWaiting, setModalIsWaiting] = useState(false);
    
    useEffect(() => {
        /*if (location.state.paymentMethods) {
            setPaymentMethods(location.state.paymentMethods);
            setLoading(false);
        }*/
        refreshData();
    }, []);

    const refreshData = async () => {
        const customerResponse = await Api.Billing.getCustomer();
        const customer = customerResponse.data;
        setPaymentMethods(customer.paymentMethods);
        console.log(customer);
        setLoading(false);
    }

    const editPaymentMethod = async (index) => {
        setSelectedPaymentMethod(paymentMethods[index]);
        setIsEditing(true);
        setIsOpen(true);
    }

    const deletePaymentMethod = async (index) => {
        const result = await Api.Billing.deletePaymentMethod(paymentMethods[index].token);
        if (result.status === 200) {
            setModal('deletedSuccess');
        }
    }

    const makePrimary = async (index, subscription) => {
        setLoading(true);
        const paymentMethod = paymentMethods[index];

        if (subscription) {
            const updateSubscriptionResponse = await Api.Billing.updateSubscription({id: subscription.id, paymentMethodToken: paymentMethod.token});
            if (updateSubscriptionResponse.data) {
                // all good
            }
        }

        const payload = {
            token: paymentMethod.token,
            makeDefault: true,
        };
        const updatePaymentMethodResponse = await Api.Billing.updatePaymentMethod(payload);
        if (updatePaymentMethodResponse.data.success) {
            // paymentMethods[index].default = true;
            refreshData();
        }
    }

    const addPaymentMethod = async () => {
        setSelectedPaymentMethod(null);
        setIsEditing(false);
        setIsOpen(true);
    }

    const breadcrumb = [
        {
            title: 'Home',
            link: '/app/claims'
        },
        {
            title: 'Billing',
            link: '/app/billing'
        },
        {
            title: 'Payment Methods',
            link: ''
        }
    ];

    const hideModal = () => {
        setModalIsWaiting(false);
        setModal('');
    }

    const hideModalAndRefresh = () => {
        setModalIsWaiting(false);
        setModal('');
        setLoading(true);
        refreshData();
    }

    const onPaymentMethodSaved = () => {
        if (isEditing) {
            setModal('updatedSuccess');
        } else {
            setModal('addedSuccess');
        }
    };

    const messages = {
        deletedSuccess: {
            title: 'Payment Method Deleted',
            body: 'The payment method has been deleted successfully.',
            buttons: [
                {
                    text: 'OK',
                    action: hideModalAndRefresh,
                    isPrimary: true,
                    isDisabled: false
                }
            ]
        },
        addedSuccess: {
            title: 'Payment Method Added',
            body: 'The payment method has been added successfully.',
            buttons: [
                {
                    text: 'OK',
                    action: hideModalAndRefresh,
                    isPrimary: true,
                    isDisabled: false
                }
            ]
        },
        updatedSuccess: {
            title: 'Payment Method Updated',
            body: 'The payment method has been updated successfully.',
            buttons: [
                {
                    text: 'OK',
                    action: hideModalAndRefresh,
                    isPrimary: true,
                    isDisabled: false
                }
            ]
        }
    };

    return (
        <div className='payment-methods'>
            {<Pagebar breadcrumbs={breadcrumb}/>}
            <h2 className="tw-text-2xl tw-font-bold tw-mb-3">Payment Methods</h2>
            {(paymentMethods?.length) &&
                <div className="row">
                    {paymentMethods.map((pm, i) => {
                        const subscription = paymentMethods
                            .map(x => x.subscriptions) // [[1,2,3],[4,5,6]]
                            .flat()                    // [1,2,3,4,5,6]
                            .find(s => ['Active','Pending','Past Due'].includes(s.status));
                        const isSubscribed = (subscription);
                        let isPrimary = false;
                        if (isSubscribed) {
                            if (pm.subscriptions.find(x => x === subscription)) {
                                isPrimary = true;
                            }
                        } else {
                            if (pm.default) {
                                isPrimary = true;
                            }
                        }
                        return (
                            <div className="col-sm-4" key={i}>
                                <div className={'cc' + ((isPrimary) ? ' selected' : '')}>
                                    <p><img className="tw-mx-auto" src={pm.imageUrl} alt={pm.cardType} /></p>
                                    <p>{pm.cardType}</p>
                                    <p>****{pm.last4}</p>
                                    <button className="btn btn-primary" onClick={() => makePrimary(i, subscription)} disabled={isPrimary} >Make Primary</button>
                                    {false && <button className="btn btn-primary" onClick={() => editPaymentMethod(i)} >Edit</button>}
                                    <button className="btn btn-danger add" onClick={() => deletePaymentMethod(i)} disabled={isPrimary} >Delete</button>
                                    {isPrimary&& <p className="tw-text-sm">Note: To change your payment method, click Add New Card. Then click Make Primary for the new card.
                                        You cannot delete a card while it is the primary card</p>}
                                </div>
                            </div>
                        );
                    })}
                    <div className="col-sm-4">
                        <div className="cc add tw-cursor-pointer" onClick={addPaymentMethod}>
                            <span style={{fontSize: 24, fontWeight: 'bold'}}>+</span><br />
                            Add New Card
                        </div>
                    </div>
                </div>
            }
            <AddPaymentMethodModal isOpen={isOpen} setIsOpen={setIsOpen} isEditing={isEditing} paymentMethod={selectedPaymentMethod} customerId={(paymentMethods?.length) ? paymentMethods[0].customerId : ''} onComplete={onPaymentMethodSaved} ></AddPaymentMethodModal>
            <Modal
                isOpen={(modal !== '')}
                className="Modal__Bootstrap modal-dialog"
                ariaHideApp={false}
            >
                {(modal !== '') && <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title tw-text-2xl" style={{float: 'left'}}>{messages[modal].title}</h4>
                        <button type="button" className="close" disabled={modalIsWaiting} onClick={hideModal}
                            ref={(node) => node?.style.setProperty("background-image", "none", "important")}
                            style={{fontSize: 24, float: 'right', width: 'auto', textIndent: 0}}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {messages[modal].body}
                    </div>
                    <div className="modal-footer">
                        {messages[modal].buttons.map((button, i) => 
                            <button key={i} type="button" 
                                className={'btn btn-' + ((button.isPrimary) ? 'primary tw-bg-primary' : 'secondary tw-bg-gray-300')}
                                onClick={button.action} disabled={modalIsWaiting || button.isDisabled}>{button.text}</button>
                        )}
                    </div>
                </div>}
            </Modal>
            {(loading) && <div style={{position: 'fixed', display: 'flex', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0, 0, 0, 0.5)'}}>
                <div style={{margin: 'auto', color: '#FFF' }}><i className="fa fa-circle-o-notch fa-spin" /> Loading...</div>
            </div>}
        </div>
    )
}