import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { Dropdown } from "../../../_common/Dropdown";
import { AttachmentFolders } from "./AttachmentsFolders";
import { useShareAttachmentsHook } from "./ShareAttachmentHook";
import { ErrorMessage } from "../../../_common/Sweetalert";
import { Notify } from "../../../../services/Notify.service";
const claimRoutePaths = {
  shareView: "/shared-claim/:shareToken",
  new: "/claims/new",
  edit: "/claims/:id",
};

export const AttachmentsTab = ({ claimId }) => {
  const { path } = useRouteMatch();
  const isSharedView = path === claimRoutePaths.shareView;

  const [selectedFolderId, setSelectedFolderId] = useState(1);
  const [selectedFilesId, setSelectedFilesId] = useState([]);
  const [isShowShareUrl, setIsShowShareUrl] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [isShowSweetAlertError, setIsShowSweetAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inboundEmailAddress, setInboundEmailAddress] = useState('');
  const [allowInboundEmail, setAllowInboundEmail] = useState(false);
  const disableHandler = () => {
    setIsShowSweetAlertError(false);
  };
  const [uploadRequest, setUploadRequest] = useState(null);

  const {
    shareAll,
    shareSelected,
    shareSelectedFolder,
    shareUpload,
    setInboundEmail
  } = useShareAttachmentsHook({
    claimId,
    selectedFolderId,
    selectedFilesId,
    setErrorMessage,
    setIsShowSweetAlertError,
    setShareUrl,
    setIsShowShareUrl,
    isSharedView,
    setUploadRequest,
    setInboundEmailAddress,
    setAllowInboundEmail
  });

  const copyOnClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    Notify.success("Copy to clipboard.");
  };

  const copyUploadRequest = () => {
    navigator.clipboard.writeText(uploadRequest.url);
    Notify.success("Upload Request URL Copied to Clipboard.");
  }

  const toggleInboundEmail = () => {
    if (allowInboundEmail) {
      setInboundEmail('');
    } else {
      setInboundEmail('UUID');
    }
  }

  return (
    <div>
      {isShowSweetAlertError && (
        <ErrorMessage
          show={isShowSweetAlertError}
          clickHandler={(e) => disableHandler(e)}
          msg={errorMessage}
        />
      )}
      {isShowShareUrl && (
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="well">
              <div className="caption">
                <h3>Share URL</h3>
                <p>{shareUrl}</p>
                <p>
                  <button
                    className="btn blue"
                    onClick={() => copyOnClipboard()}
                  >
                    Copy
                  </button>
                  <button
                    className="btn default"
                    onClick={() => setIsShowShareUrl(false)}
                  >
                    Cancel
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {uploadRequest && (
        <div className="tw-bg-[#f1f4f7] tw-p-[20px] tw-rounded">
          <p className="tw-text-2xl">Upload Request URL</p>
          <p>{uploadRequest.url}</p>
          <button className="tw-transition tw-bg-blue tw-rounded-[4px] tw-text-white tw-px-[10px] tw-py-[4px] tw-text-[13px] tw-mx-[2px] tw-align-middle
              hover:tw-bg-blue"
              onClick={() => copyUploadRequest()}>Copy</button>
          <button className="tw-transition tw-bg-[#e1e5ec] tw-rounded-[4px] tw-text-[#666] tw-px-[10px] tw-py-[4px] tw-text-[13px] tw-mx-[2px] tw-align-middle
             hover:tw-bg-[#c2cad8]"
              onClick={() => setUploadRequest(null)}>Hide</button>
        </div>
      )}
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-folder"></i>Attachments
          </div>
          <div className="actions">
            <button className="tw-transition tw-border-blue tw-border tw-rounded-[4px] tw-bg-transparent tw-text-blue tw-font-bold tw-px-[10px] tw-py-[4px] tw-text-[13px] tw-mx-[2px] tw-align-middle
              hover:tw-text-white hover:tw-bg-blue"
              onClick={() => shareUpload()}>Upload Link</button>
            <Dropdown
              title="Share Attachments"
              variant="blue-primary bold"
              isNotOutline={true}
            >
              <Dropdown.ListItem onClick={() => shareAll()}>
                Share All Attachments
              </Dropdown.ListItem>
              <Dropdown.ListItem onClick={() => shareSelected()}>
                Share Selected Files
              </Dropdown.ListItem>

              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "1",
                  })
                }
              >
                Share Documents
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "2",
                  })
                }
              >
                Share Estimates
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "3",
                  })
                }
              >
                Share Photos
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "4",
                  })
                }
              >
                Share Reports
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "5",
                  })
                }
              >
                Share Email
              </Dropdown.ListItem>
            </Dropdown>
          </div>
        </div>
        <div className="tw-relative tw-flex tw-gap-x-3">
          <div className="tw-flex tw-h-6 tw-items-center">
            <input id="inbound" name="inbound" type="checkbox" className="tw-h-4 tw-w-4 tw-rounded !tw-m-0 tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-600"
              onChange={toggleInboundEmail} checked={allowInboundEmail}></input>
          </div>
          <div className="tw-text-sm tw-leading-6">
            <label htmlFor="inbound" className="tw-font-medium tw-text-gray-900">Allow Inbound Email</label>
            {allowInboundEmail && (<p className="tw-text-gray-500">Email Address: <a href={`mailto:${inboundEmailAddress}`}>{inboundEmailAddress}</a></p>)}
          </div>
        </div>
      </div>
      <div className="portlet-body">
        <AttachmentFolders
          claimId={claimId}
          setSelectedFolderId={setSelectedFolderId}
          selectedFilesId={selectedFilesId}
          setSelectedFilesId={setSelectedFilesId}
          isSharedView={isSharedView}
        />
      </div>
    </div>
  );
};
